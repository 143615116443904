import { FloatingWhatsApp } from 'react-floating-whatsapp';

const Footer=()=>{
    return(
        <>
             <section class="call-buton"><a class="cc-calto-action-ripple" href="tel:+919142121210"><i class="fa fa-phone"></i>
</a>
</section>

{/* <section class="whatsapp-button">
  <a class="cc-calto-action-ripple" href="https://api.whatsapp.com/send?phone=+919142121210" target="_blank">
    <i class="fa fa-whatsapp"></i>
    
  </a>
</section> */}

      <FloatingWhatsApp
        phoneNumber="+919142121210"
        accountName="Trice Technologies"
        logo={`${process.env.PUBLIC_URL}/images/web/Logo-Dark.webp`} // Check this line
        allowClickAway
        message="Hello, how can I help you?"
      />
{}
      {/* Footer */}
      <footer className="section footer-classic section-sm">
        <div className="container">
          <div className="row row-30">
            <div className="col-lg-4 col-md-4 col-sm-12 wow fadeInLeft">
              <a className="brand" href="index.html">
                <img className="brand-logo-dark" src="images/web/logo.png" alt="logo" width="100" height="17" loading='lazy'/>
              </a>
              <p className="footer-classic-description offset-top-0 offset-right-25">We make eye-catching, user-friendly, and responsive designs, giving your clients equal access from mobiles, laptops, or desktops. </p>
            </div>
            <div className="col-lg-4 col-md-4 col-sm-12 wow" data-wow-delay=".3s">
              <p className="footer-classic-title">Quick Links</p>
              <ul className="footer-classic-nav-list">
                <li><a href="/">Home</a></li>
                <li><a href="/WebDesign-and-Digital-Marketing-Experts">About us</a></li>
                <li><a href="/services ">Services</a></li>
                <li><a href="/Contact-Trice-Technologies-Get-in-Touch-Today">Contacts</a></li>
              </ul>
            </div>
            <div className="col-lg-4 col-md-4 col-sm-12 wow fadeInUp address">
              <div className="d-block offset-top-0">TRICE TECHNOLOGIES <br></br><span style={{ paddingLeft: '2%' }}>2nd floor, Prime Complex,</span><div className="d-lg-block">Kalmandapam, Palakkad, Kerala 678013.</div></div>
              <div style={{ marginTop: '2%' }}>
                <a className="d-inline-block accent-link" href="mailto:#">info@tricetechnologies.in <span><img src={`${process.env.PUBLIC_URL}/images/Footer-Icon-02.webp`} width='25' height='25' alt='MailId' /></span></a><br></br>
                <a className="d-inline-block" href="tel:#" style={{ marginTop: '2%' }}>+91 9142121210 <span><img src={`${process.env.PUBLIC_URL}/images/Footer-Icon-01.webp`} width='25' height='25' alt='PhoneNo' /></span></a>
                <ul>
                  <li style={{ listStyle: 'none' }}>Mon-Sat: <span className="d-inline-block offset-left-10 text-white" style={{ marginTop: '2%' }}>9:00 AM - 6:00 PM</span></li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="container wow fadeInUp" data-wow-delay=".4s">
          <div className="footer-classic-aside">
            <p className="rights"><span style={{ fontSize: '20px' }}>&copy;&nbsp;</span><span className="copyright-year"></span>Copyright 2024 Trice Technologies. All right reserved.</p>
            <ul className="social-links">
              <li><a href="https://www.linkedin.com/company/trice-technologies-kerala/" target="_blank" rel="noopener noreferrer" aria-label="LinkedIn"><span><img src={`${process.env.PUBLIC_URL}/images/Social-Media-linkdin.webp`} alt='Linkedin' width='100%' height='100%' /></span><span class="sr-only">LinkedIn</span></a></li>
              <li>
                <a class="" href="https://www.facebook.com/tricetechnologiesindia" target="_blank" rel="noopener noreferrer" aria-label="Facebook"><span><img src={`${process.env.PUBLIC_URL}/images/Social-Media-fb.webp`} alt='Facebook' width='100%' height='100%' /></span>
                  <span class="sr-only">Facebook</span>
                </a>
              </li>
              <li>
                <a class="" href="https://www.instagram.com/trice_technologies/" target="_blank" rel="noopener noreferrer" aria-label="Instagram"><span><img src={`${process.env.PUBLIC_URL}/images/Social-Media-insta.webp`} alt='Instagram' width='100%' height='100%' /></span>
                  <span class="sr-only">Instagram</span>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </footer>
        
        </>
    )
}

export default Footer;