import { Link } from "react-router-dom";
import { useState } from "react";
import { Modal, Button, Form } from 'react-bootstrap';
import { Helmet } from 'react-helmet';
import FloatingButton from "../Services/FloatingButton";
const Sales = () => {
  const [showMore, setShowMore] = useState(false);

  const handleLoadMore = () => {
    setShowMore(true);
  };
  const [show, setShow] = useState(false);
  const [status, setStatus] = useState("");
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
      name: "",
      email: "",
      contact: "",
      location: "",
      message: "",
  });

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleChange = (e) => {
      const { name, value } = e.target;
      setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (e) => {
      e.preventDefault();

      const { name, email, contact,location, message } = formData;

      if (!name || !email || !contact || !location || !message) {
          setStatus("Please fill out all fields.");
          return;
      }

      setLoading(true);

      fetch("https://tricetechnologies.in/demo-form.php", {
          method: "POST",
          headers: {
              "Content-Type": "application/json",
          },
          body: JSON.stringify(formData),
      })
          .then((response) => response.json())
          .then((data) => {
              setLoading(false);
              if (data.status === "success") {
                  setFormData({ name: "", email: "", contact: "",location: "", message: "" });
                  setStatus("Message sent successfully!");
                 
              } else {
                  setStatus(data.message || "Failed to send message.");
                 
              }
          })
          .catch((error) => {
              setLoading(false);
              setStatus("Failed to send message. Please try again later.");
          });
  };
    return (
        <>

<Helmet>
        <title>Sales Tracking & Management App | Trice Technologies</title>
        <meta name='title' content='Sales Tracking & Management App | Trice Technologies'/>
        <meta name="description" content="Track your salesforce with the Trice Sales App. A powerful sales tracking and management app to monitor orders, performance, and streamline operations." />
        <meta name="keywords" content="" />
        <link rel="canonical" href="https://tricetechnologies.in/sales-tracking-management-app" />
        
      
     


</Helmet>
        
            <div className="container my-5 p-5 sales-bg">
              

                <div className="row pt-5 align-items-center">
            <div className="col-md-6 align-items-center sale-banner-left">
                        <div className="">
                        <img 
                            src={`${process.env.PUBLIC_URL}/images/saleapp.png`} 
                            alt="web designing Company palakkad" 
                            style={{ maxWidth: '160px', marginRight: '15px',objectFit: 'contain' }} 
                        />
                         <h1 style={{ fontSize: '25px', margin: 0 }}>All-in-one Solution for Tracking <br></br>Sales Teams, Managing Orders,<br></br> and Optimizing Distribution!</h1>
                        </div>
                        
                        
                        <br></br>
                        <div className='d-flex'>
                        <Button className="demo-btn mr-2" onClick={handleShow}>
                        Book a Demo
                    </Button>
                    <a href="" className="btn btn-secondary " style={{background: 'antiquewhite'}}>View Features</a>
                    </div>
                        <Modal show={show} onHide={handleClose}>
                        <Modal.Header>
  <Modal.Title>Book a Demo</Modal.Title>
  <button
    type="button"
    className="btn-close"
    aria-label="Close"
    onClick={handleClose} // Replace with your close function
    style={{
      background: 'transparent',
      border: 'none',
      fontSize: '1.5rem',
      fontWeight: 'bold',
      color: '#000', // Customize the color of the 'X'
    }}
  >
    &#x2715; {/* Unicode for a styled "X" close icon */}
  </button>
</Modal.Header>

                <Modal.Body>
                    {status && <p className="text-success">{status}</p>}
                    <Form onSubmit={handleSubmit}>
                        <Form.Group className="mb-3 row align-items-center" controlId="formName">
                            <Form.Label className="col-sm-3 col-form-label">Name</Form.Label>
                            <div className="col-sm-9">
                                <Form.Control
                                    type="text"
                                    name="name"
                                    value={formData.name}
                                    onChange={handleChange}
                                    className="inputs"
                                    required
                                />
                            </div>
                        </Form.Group>
                        <Form.Group className="mb-3 row align-items-center" controlId="formEmail">
                            <Form.Label className="col-sm-3 col-form-label">Email</Form.Label>
                            <div className="col-sm-9">
                                <Form.Control
                                    type="email"
                                    name="email"
                                    value={formData.email}
                                    onChange={handleChange}
                                    className="inputs"
                                    required
                                />
                            </div>
                        </Form.Group>
                        <Form.Group className="mb-3 row align-items-center" controlId="formPhone">
                            <Form.Label className="col-sm-3 col-form-label">Phone No</Form.Label>
                            <div className="col-sm-9">
                                <Form.Control
                                    type="text"
                                    name="contact"
                                    value={formData.contact}
                                    onChange={handleChange}
                                    className="inputs"
                                    required
                                />
                            </div>
                        </Form.Group>
                        <Form.Group className="mb-3 row align-items-center" controlId="formPhone">
                            <Form.Label className="col-sm-3 col-form-label">Location</Form.Label>
                            <div className="col-sm-9">
                                <Form.Control
                                    type="text"
                                    name="location"
                                    value={formData.location}
                                    onChange={handleChange}
                                    className="inputs"
                                    required
                                />
                            </div>
                        </Form.Group>
                        <Form.Group className="mb-3 row align-items-center" controlId="formDescription">
                            <Form.Label className="col-sm-3 col-form-label">Message</Form.Label>
                            <div className="col-sm-9">
                                <Form.Control
                                    as="textarea"
                                    rows={3}
                                    name="message"
                                    value={formData.message}
                                    onChange={handleChange}
                                    className="inputs"
                                    required
                                />
                            </div>
                        </Form.Group>
                        <div className="text-end">
                            <Button variant="primary" type="submit" className="md-btn" disabled={loading}>
                                {loading ? "Sending..." : "Send"}
                            </Button>
                        </div>
                    </Form>
                </Modal.Body>
            </Modal>
                    </div>
                    <div className="col-md-6 p-5">
                     
                    <img src={`${process.env.PUBLIC_URL}/images/products/sales-banner.webp`}/>
                   
                    </div>
            </div>
                
            </div>
            <div className="container-fluid py-5 sale-intro">
                <div className="container">
                <div className="row">
                    <div className="col-md-6 p-5">
                    <h1 style={{ fontSize: '47px', margin: 0 }}>Boost Efficiency and <br></br>Driving Success</h1>

                        <p className="text-justify mt-5">With the Trice Sales App, keeping track of your salesforce becomes easy. This mobile sales tracking app lets you monitor order locations, performance, and activities, ensuring you're always connected to your team's operations. Simplify order management from start to finish, keeping every step efficient and organized. Equip your sales team with clear guidance and practical insights while you maintain complete visibility and control.</p>
                        <br></br>
                        <ul class="features-list">
                          <li><a href="#distributor"><i class="fa fa-check-circle"></i> Distributor Management</a></li>
                          <li><a href="#distributor"><i class="fa fa-check-circle"></i> Stockist Management</a></li>
                          <li><a href="#zone"><i class="fa fa-check-circle"></i> Zone Creation</a></li>
                          <li><a href="#product"><i class="fa fa-check-circle"></i> Product Management</a></li>
                          <li><a href="#shop"><i class="fa fa-check-circle"></i> Shop Data Management</a></li>
                          <li><a href="#sales"><i class="fa fa-check-circle"></i> Salesperson Management</a></li>
                          <li><a href="#order"><i class="fa fa-check-circle"></i> Order Management</a></li>
                          <li><a href="#attendance"><i class="fa fa-check-circle"></i> Attendance Management</a></li>
                          <li><a href="#stock"><i class="fa fa-check-circle"></i> Stock Management</a></li>
                          <li><a href="#custom"><i class="fa fa-check-circle"></i> 100% Customization</a></li>
                        </ul>
                    </div>
                    <div className="col-md-6">
                    <img 
                            src={`${process.env.PUBLIC_URL}/images/products/sales.png`} 
                            alt="web designing Company palakkad" 
                             
                        />
                    </div>
                </div>
                </div>
            </div>

            
            <div class="container mt-5 salesapp-features">
            <div class="section-header mt-5 pt-5">
          
          <h2 style={{ fontSize: '37px',color: '#000'}}>Features</h2>

</div>

            <div className="row mt-5" id="distributor">
              <div className="col-md-6 p-5">
                <div className="feature-content">
                  <p className="title"> Distributor/Stockist Management</p>
                  <p className="desc">Effortlessly organize and manage your distributor and stockist network for streamlined operations. Ensure smooth communication, optimize workflows, and improve supply chain efficiency to foster consistent growth. A robust management system helps strengthen relationships, address challenges proactively, and boost overall productivity. By simplifying coordination, you can focus on expanding your network and achieving long-term success while maintaining operational excellence across all levels.</p>
                </div>
 
              </div>
              <div className="col-md-6">
                <img src={`${process.env.PUBLIC_URL}/images/products/stockist.webp`} className="mx-auto d-block w-80"/>
              </div>
            </div>


            <div className="row mt-5" id="zone">
            <div className="col-md-6">
                <img src={`${process.env.PUBLIC_URL}/images/products/zone.webp`} className="mx-auto d-block w-80"/>
              </div>
              <div className="col-md-6 p-5">
                <div className="feature-content">
                  <p className="title"> Zone Creation</p>
                  <p className="desc">Create and manage zones tailored to your distribution areas for streamlined operations. Assign sales representatives to specific zones, ensuring better accountability and comprehensive coverage. This approach enhances resource allocation, simplifies tracking, and promotes efficiency in reaching target markets. By defining clear boundaries and responsibilities, you can improve performance and ensure consistent service delivery across all regions.</p>
                </div>
 
              </div>
              
            </div>


            <div className="row mt-5" id="product">
              <div className="col-md-6 p-5">
                <div className="feature-content">
                  <p className="title">Product Management</p>
                  <p className="desc">Create and manage zones tailored to your distribution areas for streamlined operations. Assign sales representatives to specific zones, ensuring better accountability and comprehensive coverage. This approach enhances resource allocation, simplifies tracking, and promotes efficiency in reaching target markets. By defining clear boundaries and responsibilities, you can improve performance and ensure consistent service delivery across all regions.</p>
                </div>
 
              </div>
              <div className="col-md-6">
                <img src={`${process.env.PUBLIC_URL}/images/products/product.webp`} className="mx-auto d-block w-80"/>
              </div>
            </div>


            <div className="row mt-5" id="shop">
            <div className="col-md-6">
                <img src={`${process.env.PUBLIC_URL}/images/products/shop-data.webp`} className="mx-auto d-block w-80"/>
              </div>
              <div className="col-md-6 p-5">
                <div className="feature-content">
                  <p className="title"> Shop Data Management</p>
                  <p className="desc">Efficiently manage and maintain comprehensive records for each shop, including essential contact details, sales history, and transaction data, all in one organized system. This centralized approach helps businesses access valuable information quickly, improve operational efficiency, and make informed decisions. By consolidating shop data, businesses can streamline communication, monitor performance, and optimize strategies for growth and success. With all relevant details in one place, managing and tracking shop activities becomes seamless, ensuring better control and improved outcomes for your business.</p>
                </div>
 
              </div>
              
            </div>


         

       
     



   
<div className="row mt-5" id="sales">
              <div className="col-md-6 p-5">
                <div className="feature-content">
                  <p className="title">Salesperson Management</p>
                  <p className="desc">Monitor the performance, activities, and progress of your sales team to maximize productivity. By closely tracking key metrics such as sales targets, customer interactions, and task completion, you can pinpoint areas of strength and improvement. Regular assessments and feedback help align the team with organizational goals, enabling individual growth and enhancing overall team effectiveness. Effective salesperson management fosters a motivated, results-driven environment, ultimately leading to greater success and higher sales performance.</p>
                </div>
 
              </div>
              <div className="col-md-6">
                <img src={`${process.env.PUBLIC_URL}/images/products/salesperson.webp`} className="mx-auto d-block"/>
              </div>
            </div>

            <div className="row mt-5" id="order">
            <div className="col-md-6">
                <img src={`${process.env.PUBLIC_URL}/images/products/Order.webp`} className="mx-auto d-block w-80"/>
              </div>
              <div className="col-md-6 p-5">
                <div className="feature-content">
                  <p className="title">Order Management</p>
                  <p className="desc">Simplify the order process from placement to delivery. Track each order's precise location in real-time, ensuring full visibility throughout the lifecycle. This streamlined approach enhances accuracy, improves operational efficiency, and boosts customer satisfaction. With a clear view of where each order is at any moment, businesses can proactively manage issues and ensure timely deliveries. By optimizing the entire order journey, companies can improve service, reduce errors, and enhance customer trust, creating a more seamless and reliable experience for both businesses and customers.</p>
                </div>
 
              </div>
              
            </div>




            <div className="row mt-5" id="attendance">
              <div className="col-md-6 p-5">
                <div className="feature-content">
                  <p className="title">Attendance Management</p>
                  <p className="desc">Efficiently monitor and manage employee attendance to enhance workforce accountability. By accurately recording attendance data, businesses can optimize scheduling, ensure timely planning, and maintain productivity. This streamlined approach helps identify trends, reduce absenteeism, and improve overall workforce management, leading to better decision-making and smoother operations.</p>
                </div>
 
              </div>
              <div className="col-md-6">
                <img src={`${process.env.PUBLIC_URL}/images/products/Attendance.webp`} className="mx-auto d-block w-80"/>
              </div>
            </div>


            <div className="row mt-5" id="stock">
            <div className="col-md-6">
                <img src={`${process.env.PUBLIC_URL}/images/products/stock-m.webp`} className="mx-auto d-block w-80"/>
              </div>
              <div className="col-md-6 p-5">
                <div className="feature-content">
                  <p className="title">Stock Management</p>
                  <p className="desc">Efficient stock management is key to maintaining optimal inventory levels, ensuring that your business can meet customer demand without running into issues like stockouts or overstocking. By keeping a close eye on inventory trends and fluctuations, you can make informed decisions about restocking and reducing excess. Proper stock control helps streamline operations, reduces costs, and ensures that your customers receive products on time. Achieving this balance not only improves customer satisfaction but also enhances your overall operational efficiency and profitability.</p>
                </div>
 
              </div>
              
            </div>


            <div className="row mt-5" id="custom">
              <div className="col-md-6 p-5">
                <div className="feature-content">
                  <p className="title">100% Customization</p>
                  <p className="desc">Completely adjust the app to meet the unique needs of your business, ensuring it aligns seamlessly with your specific workflows and objectives. With full customization, you have the flexibility to modify every feature, optimizing the app’s performance to support your goals. This ensures a perfect fit for your operational requirements, helping you streamline processes and drive business success.</p>
                </div>
 
              </div>
              <div className="col-md-6">
                <img src={`${process.env.PUBLIC_URL}/images/products/customization.webp`} className="mx-auto d-block w-80"/>
              </div>
            </div>
           

            </div>
            <section class="work-porcess-area section">
            <div class="container">


        <div class="section-header mt-5 pt-5">
          
                            <h2 style={{ fontSize: '37px',color: '#000'}}>How It Works</h2>
         
        </div>
                <div class="process-info my-5">
                    <div class="row">
                        <div class="col-md-3">
                            <div class="single-process first text-center">
                                <i class="fa fa-sign-in"></i>
                                <h4 className="text-white">Sign Up</h4>
                            </div>
                        </div>
                        <div class="col-md-3">
                            <div class="single-process secend text-center">
                                <i class="fa fa-group"></i>
                                <h4>Add Your<br></br>Team</h4>
                            </div>
                        </div>
                        <div class="col-md-3">
                            <div class="single-process thard text-center">
                                <i class="fa fa-calendar"></i>
                                <h4>Track <br></br>Activities</h4>
                            </div>
                        </div>
                        <div class="col-md-3">
                            <div class="single-process last text-center">
                                <i class="fa fa-line-chart"></i>
                                <h4>Analyze Sales Performance</h4>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <FloatingButton/>
         
        </>
    );
};

export default Sales;
