import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { BrowserRouter as Router, Routes, Route, NavLink,Navigate } from 'react-router-dom';
import Header from './Header';
import Home from './Home';
import About from './About';
import Services from './Services';
import Contact from './Contact';
import Dm_blog from './Dm-blog';
import Ecommerce from './Ecommerce-blog';
import E_seo from './Seo-blog';
import Content from './Services/Content-Creation';
import DigitalMarketing from './Services/Digital-Marketing';
import PhotoVideo from './Services/Photo-Video';
import SEO from './Services/SEO';
import WebDesigning from './Services/Web-Designing';
import Blogs from './Blogs';
import ECommerce from './Services/Ecommerce';

import DmBlog from './DmBlog';
import Website from './Website';
import Professional_SEO from './Professional-SEO';
import Sales from './Products/Sales';
import EduManage from './Products/EduManage';
import College from './Products/College';
import School from './Products/School';
import OnlineStore from './Blogs/OnlineStore';
import Trends from './Blogs/Trends';
import AI from './Blogs/AI';
import ScrollToTop from './ScrollTop';
import Footer from './Footer';
import RelatedBlog from './Blogs/RelatedBlog';



function App() {

  return (
    <div className="App">

      <Router>
      <ScrollToTop />
      <Header/>

        <Routes>
        <Route path='/' element={<Home/>} ></Route>
          <Route path='/WebDesign-and-Digital-Marketing-Experts' element={<About/>} ></Route>
          <Route path='/services' element={<Services/>} ></Route>
        
          <Route path='/Trice-Technologies-Blog-Latest-Tech-Trends' element={<Blogs/>} ></Route>
          <Route path='/Contact-Trice-Technologies-Get-in-Touch-Today' element={<Contact/>} ></Route>
          <Route path='/digital-marketing-blog' element={<Dm_blog/>} ></Route>
          <Route path='/ecommerce-blog' element={<Ecommerce/>} ></Route>
          <Route path='/ecommerce-seo' element={<E_seo/>} ></Route>
          <Route path='/Best-Content-Creation-Services-in-palakkad' element={<Content/>} ></Route>
          <Route path='/best-digital-marketing-strategist-in-palakkad' element={<DigitalMarketing/>} ></Route>
          <Route path='/Professional-Photography-and-Videography-Services' element={<PhotoVideo/>} ></Route>
          <Route path='/seo-company-in-palakkad-kerala' element={<SEO/>}></Route>
          <Route path='/webdesigning-company-in-palakkad-kerala/' element={<WebDesigning/>}></Route>
          <Route path='/Digital_Marketing_Strategist' element={<DmBlog/>}></Route>
          <Route path='/cheapest-web-design-company' element={<Website/>}/>
          <Route path='/professional-seo-services' element={<Professional_SEO/>}/>
          <Route path='/ecommerce-website-development-company-in-kerala' element={<ECommerce/>}/>
          <Route path='/sales-tracking-management-app' element={<Sales/>}/>
          <Route path='/best-school-and-college-management-software' element={<EduManage/>}></Route>
          <Route path='/college-management-system' element={<College/>}></Route>
          <Route path='/school-management-software-palakkad-kerala' element={<School/>}></Route>
          <Route path='/how-to-create-my-own-online-store' element={<OnlineStore/>}></Route>
          <Route path='/digital-business-trends-2025' element={<Trends/>}></Route>
          <Route path='/ai-for-business-website' element={<AI/>}></Route>
          <Route path='/relatedBlog' element={<RelatedBlog/>}></Route>
         
          <Route path="*" element={<Navigate to="/" replace />} />
        </Routes>

        <Footer/>
      </Router>


      
    </div>
    
  );
}

export default App;
