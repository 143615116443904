import { Modal, Button, Form } from 'react-bootstrap';
import { useState } from "react";
import { Helmet } from 'react-helmet';
import EduManageFAQ from './EduManageFAQ';
import FloatingButton from '../Services/FloatingButton';
const College=()=>{
    const [show, setShow] = useState(false);
  const [status, setStatus] = useState("");
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
      name: "",
      email: "",
      contact: "",
      message: "",
  });

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleChange = (e) => {
      const { name, value } = e.target;
      setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (e) => {
      e.preventDefault();

      const { name, email, contact, message } = formData;

      if (!name || !email || !contact || !message) {
          setStatus("Please fill out all fields.");
          return;
      }

      setLoading(true);

      fetch("https://tricetechnologies.in/contact-form-handler.php", {
          method: "POST",
          headers: {
              "Content-Type": "application/json",
          },
          body: JSON.stringify(formData),
      })
          .then((response) => response.json())
          .then((data) => {
              setLoading(false);
              if (data.status === "success") {
                  setFormData({ name: "", email: "", contact: "", message: "" });
                  setStatus("Message sent successfully!");
              } else {
                  setStatus(data.message || "Failed to send message.");
              }
          })
          .catch((error) => {
              setLoading(false);
              setStatus("Failed to send message. Please try again later.");
          });
  };
    return(
        <>
         <Helmet>
        <title>EduManage</title>
        <meta name='title' content=''/>
        <meta name="description" content="" />
        <link rel="canonical" href="https://tricetechnologies.in/college-management-system" />
        
</Helmet>
        <div className="container pt-5 my-5">
            <div className="row pt-5 align-items-center mb-5 pb-5">
            <div className="col-md-6 align-items-center sale-banner-left">
                        <div className="">
                        <img 
                            src={`${process.env.PUBLIC_URL}/images/edumanage.webp`} 
                            alt="web designing Company palakkad" 
                            style={{ maxWidth: '160px', marginRight: '15px',objectFit: 'contain' }} 
                        />
                        <h1 style={{ fontSize: '40px', margin: 0,lineHeight: '46px' }}>College Management Software System in Palakkad, Kerala <br></br>All-In-One Solution</h1>
                        <ul className='pt-4 college-list'>
                            <li><i class="fa fa-check"></i> Track Student Progress & Simplify Enrollment</li>
                            <li><i class="fa fa-check"></i> Accessible on Web & Mobile</li>
                            <li><i class="fa fa-check"></i> Streamline Operations & Reduce Errors</li>
                            <li><i class="fa fa-check"></i> Boost Efficiency & Ensure Accountability</li>
                            <li><i class="fa fa-check"></i> Optimize Library Management & Resource Tracking</li>
                        </ul>
                        </div>
                       
                       
                        
                        <br></br>
                        <div className='d-flex'>
                        <Button className="demo-btn mr-2" onClick={handleShow}>
                        Book a Demo
                    </Button>
                    <a href="" className="btn btn-secondary " style={{background: 'antiquewhite'}}>View Features</a>
                    </div>
                        <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Book a Demo</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {status && <p className="text-success">{status}</p>}
                    <Form onSubmit={handleSubmit}>
                        <Form.Group className="mb-3 row align-items-center" controlId="formName">
                            <Form.Label className="col-sm-3 col-form-label">Name</Form.Label>
                            <div className="col-sm-9">
                                <Form.Control
                                    type="text"
                                    name="name"
                                    value={formData.name}
                                    onChange={handleChange}
                                    className="inputs"
                                    required
                                />
                            </div>
                        </Form.Group>
                        <Form.Group className="mb-3 row align-items-center" controlId="formEmail">
                            <Form.Label className="col-sm-3 col-form-label">Email</Form.Label>
                            <div className="col-sm-9">
                                <Form.Control
                                    type="email"
                                    name="email"
                                    value={formData.email}
                                    onChange={handleChange}
                                    className="inputs"
                                    required
                                />
                            </div>
                        </Form.Group>
                        <Form.Group className="mb-3 row align-items-center" controlId="formPhone">
                            <Form.Label className="col-sm-3 col-form-label">Phone No</Form.Label>
                            <div className="col-sm-9">
                                <Form.Control
                                    type="text"
                                    name="contact"
                                    value={formData.contact}
                                    onChange={handleChange}
                                    className="inputs"
                                    required
                                />
                            </div>
                        </Form.Group>
                        <Form.Group className="mb-3 row align-items-center" controlId="formDescription">
                            <Form.Label className="col-sm-3 col-form-label">Description</Form.Label>
                            <div className="col-sm-9">
                                <Form.Control
                                    as="textarea"
                                    rows={3}
                                    name="message"
                                    value={formData.message}
                                    onChange={handleChange}
                                    className="inputs"
                                    required
                                />
                            </div>
                        </Form.Group>
                        <div className="text-end">
                            <Button variant="primary" type="submit" className="md-btn" disabled={loading}>
                                {loading ? "Sending..." : "Send"}
                            </Button>
                        </div>
                    </Form>
                </Modal.Body>
            </Modal>
                    </div>
                    <div className="col-md-6">
                    <img src={`${process.env.PUBLIC_URL}/images/products/college-1.webp`}/>
                    </div>
            </div>

           

        </div>
        <div className='container-fluid my-5 title-strip'>
            <div className='container'>
           
        <div className='row p-5'>
            <div className='col-md-12 text-center'>
                <h2 className='text-dark' style={{color:'#000'}}>Online College Management System Software</h2>
            </div>
            <div className='col-md-6 '>
            <img src={`${process.env.PUBLIC_URL}/images/products/college-2.webp`} className='w-80'/>
            </div>
            <div className='col-md-6 text-justify p-5'>
                <p>TriceEduManage stands as the leading College Management Software System in Palakkad, Kerala, offering a seamless way to manage and streamline educational operations. Equipped with advanced features like a student information management system, attendance tracking, and holistic education management tools, it simplifies administrative tasks while enhancing overall efficiency. Trusted by institutions across Kerala, including key cities such as Calicut, Thrissur, Malappuram, Kannur, and Kochi, TriceEduManage drives productivity and ensures smooth operations. Elevate your institution’s performance and gain a competitive edge with TriceEduManage—the ultimate solution for modern education management.</p>
                <div className="text-center d-flex flex-column flex-md-row align-items-center mt-5">
  <Button className="demo-btn mb-2 mb-md-0 mr-md-2" onClick={handleShow}>
    Book a Demo
  </Button>
  <a 
    href="https://wa.me/+919142121210" 
    target='_blank' 
    className="btn btn-success" 
    style={{background: '#25d366', color: '#fff', padding: '12px', borderRadius: '3px', display: 'flex', alignItems: 'center'}}
  >
    <i className="fa fa-whatsapp" style={{fontSize: '25px', marginRight: '8px'}}></i> 
    Click here for WhatsApp Chat
  </a>
</div>

            </div>
           

        </div>
            
            
            </div>
        </div>
        <div className="container">
    <div className="row g-4 my-5">
            
            <div className='col-md-12 my-5'>
                <h3 className='text-center feature-head'>Modules of a College Management System</h3>
            </div>
        <div className="col-md-4 mt-5 ">
           
           <div className='feature-box'>
            <div className='feature-img'>

                
<img src={`${process.env.PUBLIC_URL}/images/products/accounts.png`} className=''/>
</div>
           
          
            <h5 className='text-center'>Accounts Management</h5>
           <ul>
           
            <li>Fee collection</li>
            <li>Student Payments</li>
            <li>Generating Invoices</li>
            <li>Generating Reports</li>
           </ul>
            
           
           </div>
            
        </div>


        <div className="col-md-4 mt-5 ">
           
           <div className='feature-box'>
            <div className='feature-img'>

                
<img src={`${process.env.PUBLIC_URL}/images/products/teacher.png`} className=''/>
</div>
           
          
            <h5 className='text-center'>Teachers Management</h5>
           <ul>
            <li>Subject expertise</li>
            <li>Class assignments</li>
            <li>Leave records</li>
            <li>Generate Payroll</li>
           </ul>
            
           
           </div>
            
        </div>



        <div className="col-md-4 mt-5">
           
           <div className='feature-box'>
            <div className='feature-img'>

                
<img src={`${process.env.PUBLIC_URL}/images/products/Library.png`} className=''/>
</div>
           
          
            <h5 className='text-center'>Library Management</h5>
           <ul>
            <li>Organizes book details</li>
            <li>Circulation</li>
            <li>Book reservations</li>
            <li>Overdue tracking</li>
           </ul>
            
           
           </div>
            
        </div>


        <div className="col-md-4 mt-5">
           
           <div className='feature-box'>
            <div className='feature-img'>

                
<img src={`${process.env.PUBLIC_URL}/images/products/tennis.png`} className=''/>
</div>
           
          
            <h5 className='text-center'>Co-curricular Activities Management</h5>
           <ul>
            <li>Manages student participation in non-academic events,clubs, and societies</li>
           
           </ul>
            
           
           </div>
            
        </div>


        <div className="col-md-4 mt-5">
           
           <div className='feature-box'>
            <div className='feature-img'>

                
<img src={`${process.env.PUBLIC_URL}/images/products/pay.png`} className=''/>
</div>
           
          
            <h5 className='text-center'>Payroll Management</h5>
           <ul>
            <li>employee salaries</li>
            <li> bonuses and allowances</li>
            <li>deductions</li>
            <li>generates payslips</li>
         
           
           </ul>
            
           
           </div>
            
        </div>


        <div className="col-md-4 mt-5">
           
           <div className='feature-box'>
            <div className='feature-img'>

                
<img src={`${process.env.PUBLIC_URL}/images/products/admission.png`} className=''/>
</div>
           
          
            <h5 className='text-center'>Admission Management</h5>
           <ul>
            <li>Streamlines admission process</li>
            <li>Form submission</li>
            <li>Eligibility Checking</li>
            <li>Seat allocation</li>
            <li>Fee processing</li>
           
           </ul>
            
           
           </div>
            
        </div>



        <div className="col-md-4 mt-5 ">
           
           <div className='feature-box'>
            <div className='feature-img'>

                
<img src={`${process.env.PUBLIC_URL}/images/products/staff.png`} className=''/>
</div>
           
          
            <h5 className='text-center'>Support Staff Management</h5>
           <ul>
            <li>Tracks Information</li>
            <li>Staff Roles</li>
            <li>Staff Schedules</li>
            <li>Staff Work Assignments</li>
           
           
           </ul>
            
           
           </div>
            
        </div>


        <div className="col-md-4 mt-5 ">
           
           <div className='feature-box'>
            <div className='feature-img'>

                
<img src={`${process.env.PUBLIC_URL}/images/products/store.png`} className=''/>
</div>
           
          
            <h5 className='text-center'>Store Management</h5>
           <ul>
          <li>Inventory Management</li>
          <li>Order Processing</li>
          <li>Supplier Management</li>
          <li>Product Cataloging</li>
          <li>Reports and Analytics</li>
            
           
           
           </ul>
            
           
           </div>
            
        </div>



        <div className="col-md-4 mt-5 ">
           
           <div className='feature-box'>
            <div className='feature-img'>

                
<img src={`${process.env.PUBLIC_URL}/images/products/school-bus.png`} className=''/>
</div>
           
          
            <h5 className='text-center'>Vehicle Management</h5>
           <ul>
            <li>College transport logistics</li>
            <li>Vehicle schedules</li>
            <li>Route management</li>
            <li>Fuel tracking</li>
            <li>Maintenance records</li>
            
           
           
           </ul>
            
           
           </div>
            
        </div>




        <div className="col-md-4 mt-5 ">
           
           <div className='feature-box'>
            <div className='feature-img'>

                
<img src={`${process.env.PUBLIC_URL}/images/products/exam.png`} className=''/>
</div>
           
          
            <h5 className='text-center'>Exam Management</h5>
           <ul>
            <li>Manages exam scheduling</li>
            <li>student exam registration</li>
            <li>Result processing</li>
            <li>Grade distribution</li>
           
            
           
           
           </ul>
            
           
           </div>
            
        </div>



        <div className="col-md-4 mt-5 ">
           
           <div className='feature-box'>
            <div className='feature-img'>

                
<img src={`${process.env.PUBLIC_URL}/images/products/hostel.png`} className=''/>
</div>
           
          
            <h5 className='text-center'>Hostel Management</h5>
           <ul>
            <li>Hostel Room Allocation</li>
            <li>Fee Management</li>
            <li>Student Grievances</li>
            <li>Facilities Management</li>
           
            
           
           
           </ul>
            
           
           </div>
            
        </div>




        <div className="col-md-4 mt-5 ">
           
           <div className='feature-box'>
            <div className='feature-img'>

                
<img src={`${process.env.PUBLIC_URL}/images/products/online.png`} className=''/>
</div>
           
          
            <h5 className='text-center'>Online Student Registration</h5>
           <ul>
            <li>Registration Process for New Students</li>
            <li>Data Entry</li>
            <li>Record Creation</li>
           
           
            
           
           
           </ul>
            
           
           </div>
            
        </div>




        <div className="col-md-4 mt-5 ">
           
           <div className='feature-box'>
            <div className='feature-img'>

                
<img src={`${process.env.PUBLIC_URL}/images/products/online-education.png`} className=''/>
</div>
           
          
            <h5 className='text-center'>Student Course Selection</h5>
           <ul>
            <li>Course Selection</li>
            <li>Course Registration</li>
           
           
           </ul>
            
           
           </div>
            
        </div>




        <div className="col-md-4 mt-5 ">
           
           <div className='feature-box'>
            <div className='feature-img'>

                
<img src={`${process.env.PUBLIC_URL}/images/products/certificate.png`} className=''/>
</div>
           
          
            <h5 className='text-center'>Merit List Generation</h5>
           <ul>
            <li>Merit List Generation</li>
            <li>Student Performance Evaluation</li>
            <li>Eligibility for Course Admission</li>
           
           
           </ul>
            
           
           </div>
            
        </div>



        <div className="col-md-4 mt-5 ">
           
           <div className='feature-box'>
            <div className='feature-img'>

                
<img src={`${process.env.PUBLIC_URL}/images/products/tracker.png`} className=''/>
</div>
           
          
            <h5 className='text-center'>Live Tracking of Admission Status</h5>
           <ul>
            <li>Real-Time Updates</li>
            <li>Admission Process Tracking</li>
            <li>Admission Status Monitoring</li>
           
           
           </ul>
            
           
           </div>
            
        </div>



        <div className="col-md-4 mt-5 ">
           
           <div className='feature-box'>
            <div className='feature-img'>

                
<img src={`${process.env.PUBLIC_URL}/images/products/online-pay.png`} className=''/>
</div>
           
          
            <h5 className='text-center'>Online Fees Payment</h5>
           <ul>
            <li>Fee Payment</li>
            <li>Multiple Payment Option</li>
         
           
           
           </ul>
            
           
           </div>
            
        </div>
       
    </div>
</div>
<br></br>

<EduManageFAQ/>


<FloatingButton/>

        </>
    )
}

export default College;