import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const cards = [
  { 
    type: 'image', 
    src: `${process.env.PUBLIC_URL}/images/services/tulsi-2.png`, 
    alt: "Ecommerce Product Category", 
    title: "Product Category", 
    description: "Well-organized product categories to help users explore products easily."
  },
  { 
    type: 'image', 
    src: `${process.env.PUBLIC_URL}/images/services/tulsi-3.png`, 
    alt: "Ecommerce Product Listing", 
    title: "Product Listing", 
    description: "A clean and organized product list to help users browse with ease."
  },
  { 
    type: 'image', 
    src: `${process.env.PUBLIC_URL}/images/services/tulsi-4.png`, 
    alt: "Ecommerce Product Detail", 
    title: "Product Detail", 
    description: "Detailed product pages showcasing features, pricing, and reviews."
  },
  { 
    type: 'image', 
    src: `${process.env.PUBLIC_URL}/images/services/tulsi-5.png`, 
    alt: "Ecommerce Shopping Cart", 
    title: "Shopping Cart", 
    description: "An easy-to-navigate cart for a seamless checkout experience."
  }
];




const CardSlider = () => {
  const settings = {
    dots: true,
    infinite: true,
    slidesToShow: 3, // Default for large screens
    slidesToScroll: 1,
    autoplay: true,  // Enable automatic sliding
    autoplaySpeed: 3000,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    centerMode: false,
    responsive: [
      {
        breakpoint: 1024, // For tablets and small laptops
        settings: {
          slidesToShow: 2, // Show 2 slides on smaller screens
        },
      },
      {
        breakpoint: 900, // For mobile devices
        settings: {
          slidesToShow: 1, // Show 1 slide on mobile
        },
      },
    ],
  };
  

  return (
    <div className="container pb-5 my-5" style={{ padding: '20px' }}>
                    <h2 class="wow fadeInLeft text-capitalize text-center mb-5" style={{fontSize: '27px',color: '#000'}} data-wow-delay=".3s">From Discovery to Checkout</h2>

     <Slider {...settings}>
  {cards.map((card, index) => (
    <div
      key={index}
      style={{
        padding: '0 30px',
        marginRight: '20px', // Increased space between slides
      }}
    >
      <div className='shadow p-3 mb-5 bg-white rounded'
        style={{
          boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 0px 0 #fff',
        
          overflow: 'hidden',
          textAlign: 'center',
          marginRight: '20px',
        }}
      >
        {card.type === 'image' ? (
          <>
            <img
              src={card.src}
              alt={card.alt}
              style={{ width: '100%', height: 'auto' }}
            />
            <div style={{ padding: '10px' }}>
              <h4 style={{ margin: '10px 0', fontSize: '18px', color: '#333' }}>
                {card.title}
              </h4>
              <p style={{ margin: '0', fontSize: '14px', color: '#777' }}>
                {card.description}
              </p>
            </div>
          </>
        ) : (
          <iframe
            width="100%"
            height="250px"
            src={card.src}
            title={`Video ${index + 1}`}
            frameBorder="0"
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          />
        )}
      </div>
    </div>
  ))}
</Slider>

    </div>
  );
};

// Custom arrow components
const SampleNextArrow = (props) => {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{
        ...style,
        display: 'block',
        background: '#007BFF',
        borderRadius: '50%',
      }}
      onClick={onClick}
    />
  );
};

const SamplePrevArrow = (props) => {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{
        ...style,
        display: 'block',
        background: '#007BFF',
        borderRadius: '50%',
      }}
      onClick={onClick}
    />
  );
};

export default CardSlider;
