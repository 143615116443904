import React, { useState } from "react";

const FloatingButton = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    contact: "",
    message: "",
  });
  const [status, setStatus] = useState("");
  const [loading, setLoading] = useState(false);

  const toggleForm = () => {
    setIsOpen(!isOpen);
    setStatus(""); // Clear status when toggling the form
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const { name, email, contact, message } = formData;

    if (!name || !contact || !message) {
      setStatus("Please fill out all fields.");
      return;
    }

    setLoading(true);

    fetch("https://tricetechnologies.in/contact-form-handler.php", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(formData),
    })
      .then((response) => response.json())
      .then((data) => {
        setLoading(false);
        if (data.status === "success") {
          setFormData({ name: "", email: "", contact: "", message: "" });
          setStatus("Message sent successfully!");
        } else {
          setStatus(data.message || "An error occurred.");
        }
      })
      .catch((error) => {
        setLoading(false);
        setStatus("Failed to send message. Please try again later.");
      });
  };

  return (
    <div>
      {/* Floating Button */}
      <button className="floating-contact" onClick={toggleForm}>
        <span className="vertical-text">Quick Enquiry</span>
      </button>

      {/* Contact Form */}
      {isOpen && (
        <div className="contact-form1">
          <form onSubmit={handleSubmit}>
            <div className="form-header d-flex">
              <h3>Contact Us</h3>
              <button
                type="button"
                className="close-icon"
                onClick={toggleForm}
              >
                &times;
              </button>
            </div>
            {status && <div className="status-message">{status}</div>}
            <label>
              <input
                type="text"
                name="name"
                value={formData.name}
                onChange={handleInputChange}
                placeholder="Full Name"
                required
              />
            </label>
            <label>
              <input
                type="email"
                name="email"
                value={formData.email}
                onChange={handleInputChange}
                placeholder="Email"
                required
              />
            </label>
            <label>
              <input
                type="text"
                name="contact"
                value={formData.contact}
                onChange={handleInputChange}
                placeholder="Phone no"
                required
              />
            </label>
            <label>
              <textarea
                name="message"
                value={formData.message}
                onChange={handleInputChange}
                placeholder="Type your Message..."
                required
              />
            </label>
            <button type="submit" className="btn btn-primary" disabled={loading}>
              {loading ? "Sending..." : "Send"}
            </button>
          </form>
        </div>
      )}
    </div>
  );
};

export default FloatingButton;
